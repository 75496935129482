import { createApi } from '@reduxjs/toolkit/query/react';
import { PaymentProvider } from '../Model/Payments/PaymentProvider';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export type PostOrderResponse = {
  PaymentID: string;
  PaymentURL: string;
};

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQuery,
  tagTypes: [...ErrorTags, 'Payments', 'Payment-Providers'],
  endpoints: (build) => ({
    getPaymentProvidersBySiteID: build.query<PaymentProvider[], number>({
      query: (id) => ({
        url: `/sites/${id}/payments/providers`,
        method: 'GET',
      }),
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result
          ? [
              ...result.map(
                ({ ID }) => ({ type: 'Payment-Providers', id: ID } as const)
              ),
              { type: 'Payment-Providers', id: 'LIST' } as const,
            ]
          : [{ type: 'Payment-Providers', id: 'LIST' } as const];
      },
    }),
    addPaymentProvider: build.mutation<PaymentProvider, PaymentProvider>({
      query: (paymentProvider: PaymentProvider) => ({
        url: `/sites/${paymentProvider.SiteID}/payments/providers`,
        method: 'POST',
        body: paymentProvider,
      }),
      invalidatesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return [
          { type: 'Payment-Providers', id: result?.ID },
          {
            type: 'Payment-Providers',
            id: 'LIST',
          },
        ];
      },
    }),

    updatePaymentProvider: build.mutation<PaymentProvider, PaymentProvider>({
      query: (paymentProvider: PaymentProvider) => ({
        url: `/sites/${paymentProvider.SiteID}/payments/providers/${paymentProvider.ID}`,
        method: 'PUT',
        body: paymentProvider,
      }),
      invalidatesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return [
          { type: 'Payment-Providers', id: result?.ID },
          {
            type: 'Payment-Providers',
            id: 'LIST',
          },
        ];
      },
    }),
    setProviderActive: build.mutation<void, { id: number; siteID: number }>({
      query: ({ id, siteID }) => ({
        url: `/sites/${siteID}/payments/providers/${id}/active`,
        method: 'PUT',
      }),
      invalidatesTags: (_result, error, { id }) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return [
          { type: 'Payment-Providers', id },
          {
            type: 'Payment-Providers',
            id: 'LIST',
          },
        ];
      },
    }),
    deletePaymentProvider: build.mutation<void, { id: number; siteID: number }>(
      {
        query: ({ id, siteID }) => ({
          url: `/sites/${siteID}/payments/providers/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, error, { id }) => {
          if (error?.status) {
            return getErrorTag(error?.status);
          }
          return [
            { type: 'Payment-Providers', id },
            {
              type: 'Payment-Providers',
              id: 'LIST',
            },
          ];
        },
      }
    ),
    createPaymentOrder: build.mutation<
      PostOrderResponse,
      { bookingID: number; siteID: number }
    >({
      query: ({ bookingID, siteID }) => ({
        url: `/sites/${siteID}/payments/orders`,
        method: 'POST',
        body: { BookingID: bookingID },
      }),
    }),
  }),
});

export const {
  useGetPaymentProvidersBySiteIDQuery,
  useAddPaymentProviderMutation,
  useUpdatePaymentProviderMutation,
  useDeletePaymentProviderMutation,
  useSetProviderActiveMutation,
  useCreatePaymentOrderMutation,
} = paymentsApi;
