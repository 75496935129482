import { Add, CheckCircle, Delete } from '@mui/icons-material';
import {
  Divider,
  Fab,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { ConfirmDialog } from '../../../Components/Dialog/ConfirmDialog';
import { SiteContext } from '../../../Contexts/SiteContext';
import { PaymentProvider } from '../../../Model/Payments/PaymentProvider';
import { paymentProviderTypeToString } from '../../../Model/Payments/PaymentProviderType';
import { SnackBarInfo } from '../../../Model/SnackBarInfo';
import {
  useDeletePaymentProviderMutation,
  useGetPaymentProvidersBySiteIDQuery,
  useSetProviderActiveMutation,
} from '../../../Service/Payments';
import { getErrorMessage } from '../../../Util/Error';
import { AddProviderDialog } from './AddProviderDialog';

export const Providers = () => {
  const siteID = useContext(SiteContext).site.id;

  const { data: paymentProviders } =
    useGetPaymentProvidersBySiteIDQuery(siteID);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const [currentPaymentProvider, setCurrentPaymentProvider] =
    useState<PaymentProvider>();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    useState<boolean>(false);

  const [setProviderActive] = useSetProviderActiveMutation();

  const handleSetProviderActive = async (v: PaymentProvider) => {
    try {
      await setProviderActive({
        siteID: siteID,
        id: v.ID,
      }).unwrap();
      setSnackBarInfo({
        open: true,
        message: `Payment provider ${v.Name} active`,
        severity: 'success',
      });
    } catch (e) {
      const msg = getErrorMessage(e);
      setSnackBarInfo({ open: true, message: msg, severity: 'error' });
    }
  };

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarInfo>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [deletePaymentProvider] = useDeletePaymentProviderMutation();

  const handleDeleteRequested = async (v: PaymentProvider) => {
    try {
      await deletePaymentProvider({
        siteID: siteID,
        id: v.ID,
      }).unwrap();
      setSnackBarInfo({
        open: true,
        message: `Deleted payment provider ${v.Name}`,
        severity: 'success',
      });
    } catch (e) {
      const msg = getErrorMessage(e);
      setSnackBarInfo({ open: true, message: msg, severity: 'error' });
    }
  };

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} sx={{ mb: 2 }}>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={handleOpenDialog}
        >
          <Add />
        </Fab>
        <Typography variant="h6">Manage Payment Providers</Typography>
      </Stack>
      <Grid
        container
        textAlign={'center'}
        display={'flex'}
        alignItems={'center'}
      >
        <Grid item xs={3}>
          Name
        </Grid>
        <Grid item xs={3}>
          Type
        </Grid>
        <Grid item xs={3}>
          Status
        </Grid>
        <Grid item xs={3}>
          Actions
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Divider />
        </Grid>
        {paymentProviders?.map((provider) => (
          <Fragment key={provider.ID}>
            <Grid item xs={3}>
              {provider.Name}
            </Grid>
            <Grid item xs={3}>
              {paymentProviderTypeToString(provider.ProviderType)}
            </Grid>
            <Grid item xs={3}>
              {provider.IsActive ? 'Active' : 'Inactive'}
            </Grid>
            <Grid item xs={3} display={'flex'} justifyContent={'center'}>
              <Stack direction="row" spacing={2}>
                <IconButton
                  onClick={() => {
                    setCurrentPaymentProvider(provider);
                    setOpenConfirmDeleteDialog(true);
                  }}
                  title="Delete"
                >
                  <Delete />
                </IconButton>
                <IconButton
                  color={provider.IsActive ? 'success' : 'default'}
                  onClick={() => {
                    setCurrentPaymentProvider(provider);
                    handleSetProviderActive(provider);
                  }}
                  title="Make active"
                >
                  <CheckCircle />
                </IconButton>
              </Stack>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <AddProviderDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <ConfirmDialog
        open={openConfirmDeleteDialog}
        setOpen={setOpenConfirmDeleteDialog}
        title="Delete Payment Provider"
        message="Are you sure you want to delete this payment provider?"
        value={currentPaymentProvider}
        danger
        onConfirm={handleDeleteRequested}
      />
      <Snackbar
        open={snackBarInfo.open}
        autoHideDuration={6000}
        onClose={() => setSnackBarInfo({ ...snackBarInfo, open: false })}
        title={snackBarInfo.message}
        color={snackBarInfo.severity}
      />
    </>
  );
};
